<template>
  <div class="p-3 sku-exceptions-holder">

    <header class="mb-4">
      <BackButton class="exception-BackButton"></BackButton>
      <h3>Add Ship Via exception</h3>
    </header>
    <client-exception-config></client-exception-config>
  </div>
</template>

<script>

import ClientExceptionConfig from '../../../../components/OrderManagement/ShipVia/ClientShipViaExceptionConfig.vue';
import BackButton from '../../../../components/BackButton.vue';

export default {
  name: 'EditClientExceptions.vue',
  components: {
    'client-exception-config': ClientExceptionConfig,
    BackButton,
  },
};
</script>

<style lang="scss">
  @import '../../../../styles/OrderManagement.scss';
</style>
